import React from 'react'
import dynamic from 'next/dynamic'

import { HorizontalAlignment, i18n } from '@thg-commerce/enterprise-core'
import { Image } from '@thg-commerce/gravity-elements'

import {
  CaptionTriggerButton,
  Container,
  PlayButton,
  PreviewImage,
  PreviewImageContainer,
  Title,
  TranscriptTriggerButton,
  VideoContainer,
} from './styles'
import { VideoTranscriptModal } from './VideoTranscriptModal'

const Play = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/PlayDefaultCircle'),
)

const SvgVideoTranscript = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/VideoTranscript'),
)

const SvgVideoCaption = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/VideoCaption'),
)

export interface TranscriptTriggerProps {
  onClick: (e?: React.SyntheticEvent) => void
  isVisible?: boolean
}

export const TranscriptTrigger = (props: TranscriptTriggerProps) => {
  return (
    <TranscriptTriggerButton
      sizing="regular"
      emphasis="low"
      isVisible
      aria-label="Transcript"
      onClick={(e) => {
        props.onClick(e)
      }}
      data-testid="transcript-trigger"
    >
      <SvgVideoTranscript />
    </TranscriptTriggerButton>
  )
}

export const CaptionTrigger = (props: TranscriptTriggerProps) => {
  return (
    <CaptionTriggerButton
      sizing="regular"
      emphasis="low"
      isVisible
      aria-label={props.isVisible ? 'Caption On' : 'Caption Off'}
      onClick={(e) => {
        props.onClick(e)
      }}
      data-testid="caption-trigger"
    >
      <SvgVideoCaption />
    </CaptionTriggerButton>
  )
}

const useVideoControls = (videoRef, videoContainerRef) => {
  const [
    transcriptTriggerVisible,
    setTranscriptTriggerVisible,
  ] = React.useState(false)
  const [captionTriggerVisible, setCaptionTriggerVisible] = React.useState(
    false,
  )

  React.useEffect(() => {
    const video = videoRef.current
    const videoContainer = videoContainerRef.current

    if (!video || !videoContainer) return

    let hideTimeout
    const isMobile = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(
      navigator.userAgent,
    )

    const hideDelay = isMobile ? 300 : 2700

    {
      /* Following is for desktop */
    }
    const showControls = () => {
      setTranscriptTriggerVisible(true)
      setCaptionTriggerVisible(true)
    }

    const startHideTimer = () => {
      clearTimeout(hideTimeout)
      hideTimeout = setTimeout(() => {
        setTranscriptTriggerVisible(false), setCaptionTriggerVisible(false)
      }, hideDelay)
    }

    // Mouse move on video, buttons visible
    const handleMouseMove = () => {
      showControls()
      startHideTimer()
    }

    // Mouse move out video, buttons hidden
    const handleMouseLeave = () => {
      clearTimeout(hideTimeout)
      if (!video.paused) {
        setTimeout(() => {
          setTranscriptTriggerVisible(false), setCaptionTriggerVisible(false)
        }, 200)
      }
    }

    // Video play ended with buttons visible
    const handleVideoEnded = () => {
      setTranscriptTriggerVisible(true), setCaptionTriggerVisible(true)
    }

    {
      /* Following is for mobile */
    }
    const handleTouchStart = () => {
      showControls()
    }

    const handleTouchEnd = () => {
      clearTimeout(hideTimeout)
      if (!video.paused) {
        setTimeout(() => {
          setTranscriptTriggerVisible(false)
          setCaptionTriggerVisible(false)
        }, 3000)
      }
    }

    // Hide buttons after playing video
    const handlePlay = () => {
      showControls()
      startHideTimer()
    }

    videoContainer.addEventListener('mousemove', handleMouseMove)
    videoContainer.addEventListener('mouseleave', handleMouseLeave)
    videoContainer.addEventListener('touchstart', handleTouchStart)
    videoContainer.addEventListener('touchend', handleTouchEnd)
    video.addEventListener('play', handlePlay)
    video.addEventListener('ended', handleVideoEnded)

    return () => {
      videoContainer.removeEventListener('mousemove', handleMouseMove)
      videoContainer.removeEventListener('mouseleave', handleMouseLeave)
      videoContainer.removeEventListener('touchstart', handleTouchStart)
      videoContainer.removeEventListener('touchend', handleTouchEnd)
      video.removeEventListener('play', handlePlay)
      video.removeEventListener('ended', handleVideoEnded)
      clearTimeout(hideTimeout)
    }
  }, [videoRef, videoContainerRef])

  return { transcriptTriggerVisible, captionTriggerVisible }
}

const PreviewImageComponent = ({
  previewImage,
  playVideo,
  playVideoI18nText,
  displayVideo,
}) => {
  if (displayVideo || !previewImage) return null

  return (
    <PreviewImageContainer
      isVisible
      data-testid="preview-image-and-play-button"
    >
      <PreviewImage>
        <Image
          src={[
            { url: previewImage.urls.small, breakpoint: 'xs' },
            { url: previewImage.urls.medium, breakpoint: 'sm' },
            { url: previewImage.urls.large, breakpoint: 'md' },
          ]}
          width="100%"
          height="100%"
          alt={previewImage.alt || ''}
          isAmp={false}
        />
        <PlayButton
          onClick={playVideo}
          aria-label={playVideoI18nText}
          data-testid="play-video-button"
        >
          <Play />
        </PlayButton>
      </PreviewImage>
    </PreviewImageContainer>
  )
}

const VideoComponent = ({
  videoRef,
  displayVideo,
  props,
  videoTabIndex,
  captionVisible,
}) => {
  return (
    <VideoContainer
      ref={videoRef}
      width="100%"
      autoPlay={props.autoplayVideo || false}
      muted={props.muteVideo || false}
      controls
      isVisible={displayVideo}
      tabIndex={videoTabIndex}
      aria-label={props.videoTitle?.text}
      playsInline
      crossOrigin="anonymous"
    >
      {props.videoAssetPathMp4 && (
        <source src={props.videoAssetPathMp4} type="video/mp4" />
      )}
      {props.videoAssetPathWebm && (
        <source src={props.videoAssetPathWebm} type="video/webm" />
      )}
      {captionVisible && (
        <track
          kind="captions"
          src={props.videoCaptionPathVTT}
          srclang="en"
          label="English"
          default
        ></track>
      )}
      Your browser does not support HTML video.
    </VideoContainer>
  )
}
export interface VideoHeroBannerProps {
  videoTitle: {
    text?: string
    alignment?: HorizontalAlignment
  }
  videoAssetPathMp4?: string
  videoAssetPathWebm?: string
  previewImage?: {
    urls: {
      small: string
      medium: string
      large: string
    }
    alt?: string
  }
  muteVideo?: boolean
  autoplayVideo?: boolean
  className?: string
  transcript?: string
  videoCaptionPathVTT?: string
}

export const VideoHeroBanner = (props: VideoHeroBannerProps) => {
  const [displayVideo, setDisplayVideo] = React.useState(!!props.autoplayVideo)
  const [videoTabIndex, setVideoTabIndex] = React.useState(-1)

  const videoRef = React.createRef<HTMLVideoElement>()
  const videoContainerRef = React.createRef<HTMLDivElement>()
  const { transcriptTriggerVisible, captionTriggerVisible } = useVideoControls(
    videoRef,
    videoContainerRef,
  )
  const [captionVisible, setVideoCaptionOpen] = React.useState(false)

  const playVideo = () => {
    setDisplayVideo(true)
    setVideoTabIndex(0)
    videoRef.current?.play()
  }

  const playVideoI18nText = i18n('general.play.video.button.text')

  const i18nText = {
    closeI18nText: {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.label'),
    },
    title: i18n('general.videoherobanner.transcript.title'),
  }

  const [
    videoTranscriptModalOpen,
    setVideoTranscriptModalOpen,
  ] = React.useState(false)

  return (
    <div className={props.className}>
      {props.videoTitle.text && (
        <Title data-testid="video-title" alignment={props.videoTitle.alignment}>
          {props.videoTitle.text}
        </Title>
      )}
      <Container ref={videoContainerRef}>
        <PreviewImageComponent
          previewImage={props.previewImage}
          playVideo={playVideo}
          playVideoI18nText={playVideoI18nText}
          displayVideo={displayVideo}
        />
        <VideoComponent
          videoRef={videoRef}
          displayVideo={displayVideo}
          props={props}
          videoTabIndex={videoTabIndex}
          captionVisible={captionVisible}
        />
        {props.transcript && transcriptTriggerVisible && (
          <TranscriptTrigger
            onClick={() => setVideoTranscriptModalOpen(true)}
          />
        )}
        {props.videoCaptionPathVTT && captionTriggerVisible && (
          <CaptionTrigger
            onClick={() => setVideoCaptionOpen((prevState) => !prevState)}
            isVisible={captionVisible}
          />
        )}
      </Container>
      {videoTranscriptModalOpen && (
        <VideoTranscriptModal
          i18nText={i18nText}
          content={props.transcript}
          onClose={() => setVideoTranscriptModalOpen(false)}
        />
      )}
    </div>
  )
}
